import { useTranslation } from 'next-i18next';

import Card from '@/components/BasicCard';
import { RippleBodyText01, RippleHeading04 } from '@/design';

const NotAvailable = () => {
  const { t } = useTranslation();
  return (
    <Card maxWidth="464px" data-testid="sos-customization__download__not-available" style={{ direction: 'rtl' }}>
      <RippleHeading04 pb="32px">{t('sos-package:download.notAvailable.title')}</RippleHeading04>
      <RippleBodyText01 pb="1.5em">{t('sos-package:thisAppIsCurrentlyNotAvailable')}</RippleBodyText01>
      <RippleBodyText01 pb="1em">{t('sos-package:pleaseContactIt')}</RippleBodyText01>
    </Card>
  );
};

export default NotAvailable;
