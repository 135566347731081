import { useCallback, useEffect } from 'react';

import { useTranslation } from 'next-i18next';

import getOS from '@/utils/getOS';
import { getRegion } from '@/utils/region';

import { downloadModel, useDownloadMachine } from '../machine';
import type { Category } from '../types';
import DownloadCard from './DownloadCard';
import NotAvailableCard from './NotAvailable';
import RTLDownloadCard from './RTLDownloadCard';
import RTLNotAvailableCard from './RTLNotAvailable';

type BrandlessDownloadProps = {
  packageCode: string;
};

const BrandlessDownloadPage = ({ packageCode }: BrandlessDownloadProps) => {
  const [state, send] = useDownloadMachine(packageCode);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === 'he';

  const { downloadLink } = state.context;

  const handleDownload = useCallback(
    (category: Category) => () => {
      send(downloadModel.events.DOWNLOAD(category));
    },
    [send],
  );

  useEffect(() => {
    const os = getOS();
    if (os === 'Android' || os === 'iOS') {
      // If is mobile, redirect to sos download page
      const url = getRegion() === 'eu' ? 'https://sos.splashtop.eu' : 'https://sos.splashtop.com';
      location.href = url;
      return;
    }

    if (os === 'Mac') {
      handleDownload('mac')();
    } else {
      handleDownload('win')();
    }
  }, [handleDownload]);

  const Download = isRTL ? RTLDownloadCard : DownloadCard;
  const NotAvailable = isRTL ? RTLNotAvailableCard : NotAvailableCard;

  if (state.matches('blocking')) {
    return <NotAvailable />;
  }

  return (
    <>
      <Download onWindowsDownload={handleDownload('win')} onMacDownload={handleDownload('mac')} />
      {downloadLink && (
        <iframe frameBorder="0" height="0" title="download-iframe" id="download-iframe" width="0" src={downloadLink}></iframe>
      )}
    </>
  );
};

export default BrandlessDownloadPage;
