import Head from 'next/head';
import React from 'react';

import { Box, SystemStyleObject } from '@chakra-ui/react';

type BrandlessLayoutProps = {
  /**
   * HTML title, shown in the browser's title or in the page's tab
   */
  title?: string;
  /**
   * The sx props in Chakra-UI for container or wrapper
   */
  sx?: {
    container?: SystemStyleObject;
    wrapper?: SystemStyleObject;
  };
  children: React.ReactNode;
};

// blank favicon in base64
const base64BlankIcon = 'data:image/x-icon;base64,AA';

const BrandlessLayout = ({ children, title = '', sx }: BrandlessLayoutProps) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingTop="64px" sx={sx?.wrapper}>
      <Head>
        <title>{title}</title>
        {/* overlap Splashtop favicon with blank favicon */}
        <link href={base64BlankIcon} rel="icon" type="image/x-icon" />
      </Head>
      <Box
        position="relative"
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
        alignItems="center"
        minWidth="290px"
        padding="0 15px"
        margin="auto"
        width={['100%', 750, 970, 1170]}
        minHeight="calc(100vh - 160px)"
        sx={sx?.container}
      >
        {children}
      </Box>
    </Box>
  );
};

export default BrandlessLayout;
