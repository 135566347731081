import { Box, Flex, List, ListItem } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import Card from '@/components/BasicCard';
import { RippleBodyText02, RippleDivider, RippleHeading04, RippleMacNoBorder, RippleOldWindowsNoBorder } from '@/design';
import { NumberTag } from '@/modules/Welcome/NumberTag';

type DownloadCardProps = {
  onWindowsDownload: () => void;
  onMacDownload: () => void;
};

const DownloadCard = ({ onWindowsDownload, onMacDownload }: DownloadCardProps) => {
  const { t } = useTranslation();
  return (
    <Card maxWidth="640px">
      <RippleHeading04 py="16px">{t('sos-package:downloadingSupportApp')}</RippleHeading04>
      <Box width="432px">
        <List as="ol" styleType="none" pt="48px" spacing="24px">
          <ListItem fontSize="14px" display="flex">
            <NumberTag>1</NumberTag>
            <Flex flexDirection="column" pl="16px" pt="6px">
              <RippleBodyText02>{t('sos-package:download.step1.line2')}</RippleBodyText02>
              <Flex pt="1em" align="center">
                <Box pr="8px">
                  <RippleOldWindowsNoBorder color="blue.100" />
                </Box>
                <RippleBodyText02
                  data-testid="sos-customization__download__windows"
                  color="blue.100"
                  cursor="pointer"
                  onClick={onWindowsDownload}
                >
                  {t('sos-package:download.step1.windowsDownload')}
                </RippleBodyText02>
                <Box pr="8px" pl="32px">
                  <RippleMacNoBorder color="blue.100" />
                </Box>
                <RippleBodyText02 data-testid="sos-customization__download__mac" color="blue.100" cursor="pointer" onClick={onMacDownload}>
                  {t('sos-package:download.step1.macDownload')}
                </RippleBodyText02>
              </Flex>
            </Flex>
          </ListItem>
          <ListItem fontSize="14px" display="flex">
            <NumberTag>2</NumberTag>
            <Flex flexDirection="column" pl="16px" pt="6px">
              <RippleBodyText02>{t('sos-package:download.step2.line1')}</RippleBodyText02>
              <RippleBodyText02 color="dark.40">{t('sos-package:download.step2.line2')}</RippleBodyText02>
            </Flex>
          </ListItem>
          <ListItem fontSize="14px" display="flex">
            <NumberTag>3</NumberTag>
            <Flex flexDirection="column" pl="16px" pt="6px">
              <RippleBodyText02>{t('sos-package:download.step3.line1')}</RippleBodyText02>
            </Flex>
          </ListItem>
        </List>
      </Box>

      <Box>
        <RippleDivider my="2em" />
        <RippleBodyText02>{t('sos-package:download.note.line1')}</RippleBodyText02>
        <br />
        <RippleBodyText02>{t('sos-package:download.note.line2')}</RippleBodyText02>
      </Box>
    </Card>
  );
};

export default DownloadCard;
