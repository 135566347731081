import type { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';

import { Global, css } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import colors from '@/design/theme/colors';
import BrandlessLayout from '@/layouts/brandless';
import BrandlessDownload from '@/modules/Customization/Download/Brandless/';
import getLocale from '@/utils/getLocale';

const SilentDownloadPage = () => {
  const router = useRouter();
  // package code
  const packageCode = router.query.code as string;

  const { t } = useTranslation();

  return (
    <>
      <Global
        styles={css`
          body {
            background-color: ${colors.blue[10]};
          }
        `}
      />
      <BrandlessLayout title={t('common:download')}>
        <BrandlessDownload packageCode={packageCode} />
      </BrandlessLayout>
    </>
  );
};

SilentDownloadPage.displayName = 'SilentDownloadPage';
SilentDownloadPage.skipAuth = true;

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  return {
    props: {
      ...(await serverSideTranslations(getLocale(req.headers['accept-language']), ['common', 'sos-package'])),
    },
  };
};

export default SilentDownloadPage;
